import React from 'react';
import { Link } from 'react-router-dom';

import styles from '../styles/Navbar.module.css';

function Navbar() {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        {/* ロゴ */}
        <div className={styles.logo}>
          <Link to="/">
            <img src={`${process.env.PUBLIC_URL}/static/tomoe_logo.png`} alt="Tomoe Logo" />
          </Link>
        </div>
        {/* Bookingボタン */}
        <div className={styles.bookingButtonContainer}>
          <Link to="/reservation">
            <button className={styles.bookingButton}>Booking</button>
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
