import React from 'react';
import styles from '../styles/StaffProfiles.module.css';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const staffData = [
    {
        id: 1,
        name: "倉内 大志",
        role: "オーナー",
        description: "本業は保険・不動産業。施設に飾ってあるフィギュアやポスターの多くは趣味で集めたものです。留学経験もあり、英語対応も可能ですのでお気軽にお声がけください。",
        imgSrc: "/images/staff/kurauchi2.png"
    },
    {
        id: 2,
        name: "加藤木 敦也",
        role: "マネージャー",
        description: "巴.com1号棟の住民。現在英語を勉強中。最高の滞在を提供できるよう努めています。困ったことなどがありましたお気軽にご連絡ください。",
        imgSrc: "/images/staff/atsuya2.jpeg"
    }
];

function StaffProfiles() {
    return (
        <div className={styles.staffContainer}>
            <h1 className={styles.title}>Staff</h1>
            <Row className="g-4 d-flex justify-content-center">
                {staffData.map((staff) => (
                    <Col key={staff.id} xs={12} sm={6} md={4}>
                        <Card className={styles.card}>
                            <Card.Img variant="top" src={staff.imgSrc} className={styles.image} />
                            <Card.Body>
                                <Card.Title className={styles.name}>{staff.name}</Card.Title>
                                <Card.Subtitle className={`mb-2 text-muted ${styles.role}`}>{staff.role}</Card.Subtitle>
                                <Card.Text className={styles.description}>
                                    {staff.description}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default StaffProfiles;
