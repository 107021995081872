import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/StaySteps.css'; // スタイルは別途記述

function StaySteps() {
    const steps = [
        { id: 1, title: '施設を選ぶ', description: 'ご希望の施設を選択してください。' },
        { id: 2, title: '予約する', description: '希望の日時で予約を確定します。' },
        { id: 3, title: '支払いする', description: 'ご予約の際に予約ページにて事前決済をお願いいたします。Stripeを使用し、安全で簡単な決済を行います。' },
        { id: 4, title: '宿泊者情報の入力', description: 'メールアドレス宛にお送りするフォームから必要な情報を入力してください。' },
        { id: 5, title: 'チェックイン案内', description: '登録いただいたメールアドレス宛にチェックイン方法に関する詳細をお知らせします。' },
        { id: 6, title: 'セルフチェックイン', description: 'お客様にて宿泊場所に直行していただきます。指定された方法でチェックインを行います。' },
    ];

    return (
        <section className="stay-steps">
            <Container>
                <h2 className="text-center mb-5 section-title">宿泊までの流れ</h2>
                <Row className="justify-content-center">
                    {steps.map((step) => (
                        <Col key={step.id} xs={12} sm={6} md={4} className="mb-4 text-center step-card">
                            <div className="step-icon">
                                <span>{step.id}</span>
                            </div>
                            <h5 className="step-title">{step.title}</h5>
                            <p className="step-description">{step.description}</p>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
}

export default StaySteps;
