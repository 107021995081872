import React, { useState, useEffect, useRef } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { FaCalendarAlt, FaUsers, FaSearch } from "react-icons/fa";
import styles from "../styles/Search.module.css";

const SearchBar = ({ onSearch }) => {
    const savedData = JSON.parse(sessionStorage.getItem('searchData')) || {};

    const [dateRange, setDateRange] = useState([
        savedData.checkIn ? new Date(savedData.checkIn) : null,
        savedData.checkOut ? new Date(savedData.checkOut) : null,
    ]);

    const [activeSection, setActiveSection] = useState(null);

    const [guests, setGuests] = useState(
        savedData.guests || {
            adult: 1,
            child: 0,
            infant: 0,
        }
    );

    const calendarRef = useRef(null);
    const guestMenuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                (calendarRef.current && calendarRef.current.contains(event.target)) ||
                (guestMenuRef.current && guestMenuRef.current.contains(event.target))
            ) {
                return;
            }
            setActiveSection(null);
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const increment = (type) => {
        setGuests((prev) => ({
            ...prev,
            [type]: prev[type] + 1,
        }));
    };

    const decrement = (type) => {
        setGuests((prev) => ({
            ...prev,
            [type]: prev[type] > 0 ? prev[type] - 1 : 0,
        }));
    };

    const totalGuests = guests.adult + guests.child;

    const handleDateChange = (range) => {
        if (Array.isArray(range) && range.length === 2) {
            setDateRange(range);
        }
    };

    const clearDates = () => {
        setDateRange([null, null]);
    };

    const formatDay = (locale, date) => {
        return date.getDate();
    };

    const handleSearch = () => {
        const searchData = {
            checkIn: dateRange[0],
            checkOut: dateRange[1],
            guests,
        };

        // 保存
        sessionStorage.setItem('searchData', JSON.stringify(searchData));

        // 検索実行
        onSearch(searchData);
    };

    return (
        <div className={styles.container}>
            {/* ゲスト人数選択 */}
            <div
                ref={guestMenuRef}
                className={styles.section}
                onClick={() =>
                    setActiveSection(activeSection === "guest" ? null : "guest")
                }
            >
                <FaUsers />
                <span>ゲスト {totalGuests}人</span>
                {activeSection === "guest" && (
                    <div className={styles.menu}>
                        {["adult", "child", "infant"].map((type, index) => (
                            <div key={index} className={styles.menuItem}>
                                <div>
                                    {type === "adult" ? "大人" : type === "child" ? "子ども" : "乳幼児"}
                                    <small>
                                        {type === "adult"
                                            ? "13歳以上"
                                            : type === "child"
                                            ? "2〜12歳"
                                            : "2歳未満"}
                                    </small>
                                </div>
                                <div>
                                    <button
                                        className={styles.button}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            decrement(type);
                                        }}
                                    >
                                        −
                                    </button>
                                    <span>{guests[type]}</span>
                                    <button
                                        className={styles.button}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            increment(type);
                                        }}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* カレンダー */}
            <div
                className={styles.datePickerWrapper}
                onClick={() =>
                    setActiveSection(activeSection === "calendar" ? null : "calendar")
                }
            >
                <div className={styles.dateInput}>
                    <span className={styles.dateLabel}>チェックイン</span>
                    <span>
                        {dateRange[0]
                            ? dateRange[0].toLocaleDateString("ja-JP")
                            : "YYYY/MM/DD"}
                    </span>
                </div>
                <div className={styles.dateInput}>
                    <span className={styles.dateLabel}>チェックアウト</span>
                    <span>
                        {dateRange[1]
                            ? dateRange[1].toLocaleDateString("ja-JP")
                            : "YYYY/MM/DD"}
                    </span>
                </div>
                {activeSection === "calendar" && (
                    <div
                        className={styles.calendarPopup}
                        ref={calendarRef}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Calendar
                            onChange={handleDateChange}
                            selectRange={true}
                            formatDay={formatDay}
                            locale="ja-JP"
                            value={dateRange}
                        />
                        <div className={styles.footer}>
                            <button onClick={clearDates} className={styles.clearButton}>
                                日付をクリア
                            </button>
                            <button
                                onClick={() => setActiveSection(null)}
                                className={styles.closeButton}
                            >
                                閉じる
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {/* 検索ボタン */}
            <button className={styles.searchButton} onClick={handleSearch}>
                検索
            </button>
        </div>
    );
};

export default SearchBar;
