import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Reservation from './pages/Reservation';
import FacilityDetail from './pages/FacilityDetail'; // 詳細ページコンポーネント
import ReservationDetails from './pages/ReservationDetails.js';
import Success from './pages/Success.js';
import Cancel from './pages/Cancel.js';


import Navbar from './components/Navbar';  // ナビゲーションバーのインポート


// English
import EnHome from './en/Home.js';
import EnFacilityDetail from './en/FacilityDetail.js';
import EnReservation from './en/Reservation.js';
import EnResearvationDetails from './en/ReservationDetails.js';


function App() {




  return (
    <Router>
      <div>

        {/* <Navbar />   */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/reservation" element={<Reservation />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
          <Route path="/facility/:id" element={<FacilityDetail />} /> {/* 遷移先のルート */}
          <Route path="/reservationDetails" element={<ReservationDetails />} /> {/* 遷移先のルート */}
          <Route path="/success" element={<Success />} /> {/* 遷移先のルート */}
          <Route path="/cancel" element={<Cancel />} /> {/* 遷移先のルート */}

          {/* English */}
          <Route path="/english" element={<EnHome />} />
          <Route path="/english/facility/:id" element={<EnFacilityDetail />} />
          <Route path="/english/reservation" element={<EnReservation />} />
          <Route path="/english/reservationDetails" element={<EnResearvationDetails />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
