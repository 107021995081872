import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/SuccessCancel.css';
import Navbar from '../components/Navbar';

function Cancel() {
    const navigate = useNavigate();

    return (
        <>
            <Navbar />
            <div className="success-cancel-container">
                <div className="info-section">
                    <div className="step-header">
                        <span className="step-number">✖</span>
                        <h5 className="step-title">決済キャンセル</h5>
                    </div>
                    <h1 className="title text-danger">決済がキャンセルされました</h1>
                    <p className="message">決済が完了しませんでした。もう一度お試しください。</p>
                    <button 
                        onClick={() => navigate('/reservation')} 
                        className="btn btn-danger mt-4"
                    >
                        予約ページに戻る
                    </button>
                </div>
            </div>
        </>
    );
}

export default Cancel;
