import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/SuccessCancel.css';
import Navbar from '../components/Navbar';

function Success() {
    const navigate = useNavigate();

    return (
        <>
            <Navbar />
            <div className="success-cancel-container">
                <div className="info-section">
                    <div className="step-header">
                        <span className="step-number">✔</span>
                        <h5 className="step-title">決済完了</h5>
                    </div>
                    <h1 className="title text-success">決済が完了しました！</h1>
                    <p className="message">ご予約ありがとうございます。詳細はご登録のメールアドレスに送信されます。</p>
                    <button
                        onClick={() => navigate('/')}
                        className="btn btn-primary"
                    >
                        ホームに戻る
                    </button>
                </div>
            </div>
        </>
    );
}

export default Success;
